/*
  Step indicator
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.nav-tabs {
  font-family: "FagoWebPro-Medium";
  font-size: 0.8rem;
  width: 100%;
  @media (min-width: 40rem) {
    margin-left: -2rem;
    width: calc(100% + 4rem);
  }
  .nav-link {
    color: $prh-dark-gray-87;
    font-family: $font-family-base;
    border: 1px solid $prh-light-gray;
    text-decoration: none;
    background-color: $white;
    padding: 0.75rem 0;
    margin: 0.1px;
    position: relative;
    &.arrow {
      font-size: 1.2rem;
      line-height: 1.1rem;
      font-weight: 400;
    }
    &.active {
      border-color: transparent;
      background-color: $prh-blue;
      color: $white;
    }
    &:focus {
      box-shadow: 0px 0px 0px 2px rgba($prh-bright-blue, 1) !important;
      z-index: 1;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active,
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
      color: $prh-dark-gray-50;
      border: 1px solid $prh-light-gray;
    }
    &:hover,
    &:active {
      text-decoration: none;
      border: 1px solid $prh-purple;
      color: $white;
      background-color: $prh-purple;
    }
    &.error {
      color: $prh-orange;
      border: 1px solid $danger;
      @media (min-width: 768px) {
        padding-right: 20px;
      }
    }
    &.error:before {
      position: absolute;
      top: 0.7rem;
      right: 0.6rem;
      content: "";
      width: 1.2rem;
      height: 1.2rem;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23C85204" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"%3E%3Ccircle cx="12" cy="12" r="10"%3E%3C/circle%3E%3Cline x1="12" y1="8" x2="12" y2="12"%3E%3C/line%3E%3Cline x1="12" y1="16" x2="12.01" y2="16"%3E%3C/line%3E%3C/svg%3E');
      background-size: contain;
      background-repeat: no-repeat;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    &.error:hover {
      color: $white;
      border: 1px solid $prh-purple;
      &.error:before {
        position: absolute;
        top: 0.7rem;
        right: 0.6rem;
        content: "";
        width: 1.2rem;
        height: 1.2rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"%3E%3Ccircle cx="12" cy="12" r="10"%3E%3C/circle%3E%3Cline x1="12" y1="8" x2="12" y2="12"%3E%3C/line%3E%3Cline x1="12" y1="16" x2="12.01" y2="16"%3E%3C/line%3E%3C/svg%3E');
      }
      @media only screen and (max-width: 768px) {
        &:after {
          position: fixed;
          font-weight: bolder;
          content: "!";
          margin-left: -5px;
          width: 16px;
          height: 16px;
        }
      }
    }
    &.error:after {
      @media only screen and (max-width: 768px) {
        position: fixed;
        font-weight: bolder;
        content: "!";
        margin-left: -5px;
        width: 16px;
        height: 16px;
      }
    }
  }
}
