/*
  Modal 
  for Bootstrap 4.0
  ------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/

.modal-content {
  box-shadow: 3px 3px 5px rgba( $prh-dark-gray, 0.15%);
}

.modal-header {
  padding-bottom: 0;

  /* Give margin to first element but not for the close button */
  & > *:first-child:not(.close) {
    margin-top: 1rem;
  }

  button:focus {
    outline: 2px solid $prh-blue;
  }
}

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-footer {
  padding: 1.5rem 2rem;
}
