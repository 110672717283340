.menu-text {
  background-color: $prh-blue;
  color: white;
  margin-left: 1rem;
  font-family: "FagoWebPro-Medium";
  font-size: 1.4rem;
}

.active-menu-text {
  /*   background-color: $prh-purple; */
  text-decoration: underline;
  color: white;
  margin-left: 1rem;
  font-family: "FagoWebPro-Medium";
  font-size: 1.4rem;
}

/* .active-link {
  background-color: $prh-purple;
} */

.prh-site-header {
  .prh-site-header__container {
    .navbar {
      button {
        border: 0px;
        background-color: $prh-blue;
      }
      .navbar-toggler-icon {
        border: 0px;
        background-color: $prh-blue;
      }
    }
  }
}
