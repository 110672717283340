/*
  Buttons
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.btn {
  & > *:last-child {
    margin-left: 0.5rem;
  }
  & > *:first-child {
    margin-right: 0.5rem;
  }
  .prh-icon {
    height: 1rem;
    width: 1rem;
    padding: 0;
  }
  .badge {
    line-height: 1;
  }
}
.badge {
  &.hasLink {
    position: relative;
    padding-right: 1.25rem;
    .close {
      position: absolute;
      font-size: 1rem;
      float: none;
      top: 0.1rem;
      right: 0.25rem;
      opacity: 1;
      width: 0.9rem;
      height: 0.9rem;
      line-height: 0.85rem;
      color: $prh-dark-gray !important;
      &:focus,
      &:hover {
        color: $prh-purple !important;
      }
      &:focus {
        outline: 2px solid $prh-blue;
      }
      &:active {
        color: $prh-dark-gray !important;
      }
    }
  }
}
.badge-primary,
a.badge-primary {
  color: $white;
  background-color: $prh-blue;
  &:hover,
  &:focus,
  &:active {
    background-color: $prh-purple !important;
  }
  &:focus,
  &:active {
    box-shadow: 0px 0px 0px 2px $prh-blue, inset 0px 0px 0px 1px $white;
    border-color: $white;
  }
}
.badge-secondary {
  color: $prh-dark-gray;
  background-color: $prh-light-gray;
}
.badge-light {
  color: $prh-blue;
  background-color: $white;
}
.badge-danger {
  color: $white;
  background-color: $prh-orange;
}

.btn {
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
  margin-bottom: 6px;
  margin-top: 6px;
  min-width: 7rem;
  letter-spacing: 0.015rem;
  /*&:focus {
    box-shadow: 0px 0px 0px 2px rgba($prh-blue, 1);
    position: relative;
    z-index: 1;
  }*/
  // space between primary and secondary buttons
  & {
    margin-right: 20px;
  }
  // space between tertiary button and any other button
  &.btn-tertiary {
    margin-right: 10px;
  }
  // finally, for the last have no right margin at all
  &:last-child {
    margin-right: 0px;
  }
}

.btn-fluid {
  margin: 0;
}

.btn-dropdown-caret {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  max-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  margin-left: 0.75rem;
  margin-right: 0.25rem;
  margin-top: 0.33rem;
  padding: 0;
  background-color: $prh-dark-blue;
  border-radius: 3px !important;
  &::after {
    margin-left: 0px;
    vertical-align: 1em;
  }
  &.show,
  &:focus,
  &:focus-within,
  &:active,
  &:hover {
    background-color: $prh-bright-blue !important;
  }
  &:active {
    border: 0 !important;
  }
  &.show,
  &:focus-within,
  &:focus {
    outline: 2px solid white;
    border: 0 !important;
  }
  &.dropdown-toggle::after {
    transition: all 0.05s;
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 2px;
    vertical-align: middle;
  }
  &.dropdown-toggle.show::after {
    transform: rotateX(180deg);
    transform: translateY(0) rotate(180deg);
  }
}
.btn-group > .btn-dropdown-caret {
  margin-left: 0.5rem !important;
}


// prepare attributes sets for primary button states
@mixin btn-primary-hover {
  background-color: $prh-purple;
  border-color: $prh-purple;
}
@mixin btn-primary-focus {
  box-shadow: 0px 0px 0px 2px $prh-blue, inset 0px 0px 0px 1px $white;
  background-color: $prh-purple;
  border-color: $white;
  .prh-icon {
    stroke: $white;
  }
}
// primary button rules
.btn-primary {
  background-color: $prh-blue;
  border-color: $prh-blue;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  &:hover {
    @include btn-primary-hover;
  }
  &:focus,
  &:active {
    @include btn-primary-focus;
  }
  &:disabled,
  &.disabled {
    box-shadow: 0px 0px 0px 0px $prh-dark-gray-50;
    background-color: $prh-dark-gray-50;
    border-color: $prh-dark-gray-50;
  }
}
.prh-site-header .btn-primary,
.bg-primary .btn-primary {
  background-color: $prh-bright-blue;
  border-color: $prh-bright-blue;
  &:hover {
    @include btn-primary-hover;
  }
  &:focus,
  &:active {
    @include btn-primary-focus;
  }
}

.btn-primary--lowercase {
  text-transform: none;
  letter-spacing: 0.015rem;
}

.btn-primary--light {
  color: $white;
  background-color: $prh-bright-blue;
  border-color: $prh-bright-blue;
  text-transform: none;
  letter-spacing: 0.015rem;
  @media (max-width: 767px) {
    padding: 8px;
  }
}

// prepare attributes sets for secondary button states
@mixin btn-secondary-hover {
  background-color: $white;
  border-color: $prh-purple;
  color: $prh-purple;
}
@mixin btn-secondary-focus {
  box-shadow: 0px 0px 0px 2px $white, inset 0px 0px 0px 2px $prh-blue;
  background-color: $white;
  border-color: $prh-blue;
  color: $prh-purple;
}
// secondary button rules
.btn-secondary {
  background-color: $white;
  color: $prh-blue;
  border-color: $prh-blue;
  &:hover {
    @include btn-secondary-hover;
  }
  &:active,
  &:focus {
    @include btn-secondary-focus;
    .prh-icon--blue {
      stroke: $prh-purple;
    }
  }
  &:disabled,
  &.disabled {
    color: $prh-dark-gray-50;
    background-color: $white;
    border-color: $prh-dark-gray-50;
  }
}

// prepare attributes sets for tertiary button states
@mixin btn-tertiary-hover {
  border-color: $prh-purple;
  color: $prh-purple;
}
@mixin btn-tertiary-focus {
  box-shadow: 0px 0px 0px 0px $prh-blue;
  border-color: $prh-blue;
  color: $prh-purple;
}
// tertiary button rules
.btn-tertiary {
  color: $prh-blue;
  &:hover {
    @include btn-tertiary-hover;
  }
  &:focus {
    @include btn-tertiary-focus;
  }
  &:active {
    box-shadow: 0px 0px 0px 0px $prh-blue !important;
    border-color: $prh-blue !important;
    color: $prh-purple !important;
  }
}

.btn-icon {
  color: $white;
  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    color: $prh-purple;
  }
  &:hover,
  &:active {
    border-color: $prh-purple;
  }
  &:disabled,
  &.disabled {
    color: $prh-dark-gray-50;
    text-decoration: none;
  }
}

// prepare attributes set for tertiary icon states
@mixin btn-tertiary-icon-hover {
  color: $prh-purple;
}
@mixin btn-tertiary-icon-focus {
  color: $prh-purple;
}
// tertiary button with icon rules
.btn-tertiary-icon {
  min-width: 0;
  /*padding: 0.125rem;*/
  > *:last-child {
    margin-left: 0px;
  }
  &:hover {
    @include btn-tertiary-icon-hover;
  }
  &:focus {
    @include btn-tertiary-icon-focus;
    svg {
      stroke: $prh-purple;
    }
  }
}

// Used in collapse
.btn-link--gray {
  background-color: $prh-lightest-gray;
  border: 1px solid $prh-medium-gray;
}

.btn-link-fluid {
  padding: 0;
}

/* Close button color override to ensure contrast */

.close {
  color: #000000 !important;
  background-color: transparent;
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 $white;
  opacity: 0.5;
}

.btn-block {
  width: 100%;
}

/* Tooltips */

.tooltip-btn {
  border-radius: 9px;
  border-width: 1px;
  width: 18px;
  height: 18px;
  padding: 0;
  position: relative;
  font-size: 12px;
  text-align: center;
  margin: 0 0.5rem 0.25rem 0.5rem;
  line-height: 17px;
  span {
    display: block;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
  }
}

.btn-popover,
.btn-tooltip,
.btn-icon {
  padding: 0;
  min-width: 0;
  &.btn-lg .prh-icon {
    height: 2.4rem;
    width: 2.4rem;
    padding: 0.5rem;
  }
  .prh-icon {
    height: 1.6rem;
    width: 1.6rem;
    padding: 0.25rem;
    margin: 0;
  }
  label & .prh-icon {
    height: 1.5rem;
  }
}
.btn-popover {
  &.btn-lg .prh-icon {
    padding: 0.4rem 0.5rem 0.6rem 0.5rem;
  }
}

.btn-icon-solo {
  padding: 0;
  min-width: 0;
  text-decoration: none;
  transition: none;
  &:hover,
  &:focus,
  &:active {
    color: $prh-purple;
    .prh-icon--blue {
      stroke: $prh-purple;
    }
    .badge-overlay {
      background-color: $prh-purple;
    }
  }
  &:focus {
    outline: 2px solid $prh-blue;
    .badge-light {
      color: $white;
    }
  }
  &:focus,
  &:active {
    outline: 2px solid $prh-blue;
    .prh-icon--blue {
      stroke: $prh-purple;
    }
  }
  &:hover {
    .prh-icon--blue {
      stroke: $prh-purple;
    }
  }
  .bg-primary &:focus,
  .bg-primary &:active {
    .prh-icon--white {
      stroke: $white;
    }
  }
}

/* A long list of general button active state rules to override BS defaults 
 * This is pretty long, because BS makes it that way.
 */
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $prh-purple;
  border-color: $white;
}
.bg-primary .btn-primary:not(:disabled):not(.disabled):active {
  border-color: $white;
}
.show.btn-secondary.dropdown-toggle {
  color: $prh-purple;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show.btn-primary.dropdown-toggle:focus {
  box-shadow: 0px 0px 0px 2px $prh-blue, inset 0px 0px 0px 1px $white;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: $white;
  border-color: $prh-blue;
  color: $prh-purple;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0px 0px 0px 2px $white, inset 0px 0px 0px 2px $prh-blue;
}



/* Badges as buttons */

.badge-overlay {
  position: absolute;
  left: 2.25em;
  top: -0.15em;
  text-decoration: none !important;
  transition: none;
  a:hover &,
  a:active &,
  .btn:hover &,
  .btn:active {
    color: $white;
    background-color: $prh-purple;
  }
}

.badge-info {
  color: $white;
  background-color: $prh-blue;
}

.badge-notification {
  background-color: $prh-medium-purple;
}

.badge-dark {
  color: $white;
  background-color: $prh-dark-gray-87;
}
.badge {
  a & {
    text-decoration: none;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
  }
}
