/*
  Pagination
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/
.pagination {
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
}
.prh-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.prh-pagination__page-count {
  margin: 0.5rem 1rem;
  color: $prh-dark-gray-87;
}



/* .page-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 33px;
} */
